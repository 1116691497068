import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { User } from '../users.component';
import {ConfirmationService} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-user-show',
  templateUrl: './user-show.component.html',
  styleUrls: ['./user-show.component.css'],
  providers: [ConfirmationService]
})
export class UserShowComponent implements OnInit {
  @ViewChild('usernameinput') usernameInput: ElementRef

  @Input() selectedUser: User;
  savedUser: User = {id: -1, username: "", password: "", used: null};

  @Output() editUserEvent = new EventEmitter<User>();
  @Output() deleteUserEvent = new EventEmitter<User>();

  editable: Boolean = false;
  showPassword: Boolean = false;

  constructor(private confirmationService: ConfirmationService, private primengConfig: PrimeNGConfig) {}

  ngOnInit() {this.primengConfig.ripple = true; /* this.editedUser = this.selectedUser */}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedUser) {
      this.editable = false;
      this.showPassword = false;
    }
  }

  editClick() {
    if (this.selectedUser.id != -1) {
      if (this.editable) {
        this.editUser()
      } else {
        this.edit()
        setTimeout(() => {
          this.usernameInput.nativeElement.focus()
        })
      }
      this.editable = !this.editable
    }
  }

  edit(id: number = null, username: string = null, password: string = null, used: number = null) {
    this.savedUser.id = id == null ? this.selectedUser.id : id
    this.savedUser.username = username == null ? this.selectedUser.username : username
    this.savedUser.password = password == null ? this.selectedUser.password : password
    this.savedUser.used = used == null ? this.selectedUser.used : used
  }

  cancel() {
    this.selectedUser.id = this.savedUser.id
    this.selectedUser.username = this.savedUser.username
    this.selectedUser.password = this.savedUser.password
    this.selectedUser.used = this.savedUser.used
    this.editable = false
  }
  
  editUser() {
    this.editUserEvent.emit(this.selectedUser)
  }

  deleteUser() {
    if (this.selectedUser.id > -1) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete this user permanently?',
        header: 'Delete User?',
        icon: 'pi pi-info-circle',
        acceptLabel: 'Delete \''+this.selectedUser.username+'\'',
        acceptIcon: 'pi pi-trash',
        acceptButtonStyleClass: 'p-button-rounded p-button-raised p-button-danger',
        rejectButtonStyleClass: 'p-button-rounded p-button-raised p-button-info',
        rejectLabel: "Cancel",
        accept: () => {
          this.deleteUserEvent.emit(this.selectedUser)
        }
      });
    }
  }
}
