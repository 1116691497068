import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { tap, delay, map, catchError } from 'rxjs/operators';
import { DatabaseService } from '../database.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isLoggedInSubject: BehaviorSubject<boolean>
  public isLoggedIn: Observable<boolean>

  constructor(private database: DatabaseService) {
    this.isLoggedInSubject = new BehaviorSubject<boolean>(localStorage.getItem('logged') == 'yes')
    this.isLoggedIn = this.isLoggedInSubject.asObservable()
  }

  public get isLoggedInVal(): boolean {return this.isLoggedInSubject.value}

  authLogin(password: string) {
    console.log("NeverAuthLogin");
    return this.database.dbLogin(password).pipe(
      map((val: boolean) => {
        localStorage.setItem('logged', val ? 'yes' : 'no')
       this.isLoggedInSubject.next(val)
       return val
      })
    )
  }

  logout() {
    localStorage.setItem('logged', 'no')
    this.isLoggedInSubject.next(false)
  }
}
