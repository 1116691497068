<h2 *ngIf="!authService.isLoggedInVal">LOGIN</h2>

<p-toast position="bottom-left" key="t" [baseZIndex]="5000"></p-toast>

<br>

<div *ngIf="!authService.isLoggedInVal" class="p-float-label" style="float:left;margin-right:12px">
    <input id="password-input" #passwordinput [type]="showPassword ? 'text' : 'password'" class="form-control" pInputText
    [ngClass]="incorrect ? 'form-control p-invalid' : 'form-control'"
    [(ngModel)]="password" (keyup.enter)="doLogin()" aria-describedby="password-input-help" >
    <label for="password-input">Password</label>
</div>

<button *ngIf="!authService.isLoggedInVal" pButton pRipple type="button" [icon]="showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'" 
class="p-button-rounded p-button-text"
(click)="showPassword = !showPassword" style="float:left;margin-right:24px;"></button>

<button *ngIf="!authService.isLoggedInVal" pButton pRipple #loginbtn type="button" icon="pi pi-user" 
class="p-button-rounded p-button-success p-button-raised"
[ngClass]="password=='' ? 'p-button-rounded p-button-success p-button-raised p-button-outlined' : 'p-button-rounded p-button-success p-button-raised'"
label="Login" style="float:left;margin-right:16px;"
(click)="doLogin()" [disabled]="password==''"></button>

<br>
<br>
<br>

<small *ngIf="incorrect" id="password-input-help" class="p-invalid">Password is incorrect.</small>