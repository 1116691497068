import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { User } from './users/users.component';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  
  readonly baseUrl = 'https://webtalent-sms.ussl.app/database'
  readonly requestOptions = {                                                                                                                                                                                 
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Origin': '*',
    }),
  };

  users: User[]
  private password: string = localStorage.getItem('password')

  constructor(private http: HttpClient) {}

  dbLogin(password: string = this.password) {
    this.password = password
    localStorage.setItem('password', password)
    return this.http.post(`${this.baseUrl}/login.php`, {data: null, pass: this.password}).pipe(
      map((res) => {
        console.log(res)
        return res['logged']
      }),
      catchError(this.handleError)
    )
  }

  getAll(): Observable<User[]> {
    return this.httpDo("get_all")
  }

  editUser(user: User) {
    return this.httpDo("edit_user", user)
  }

  deleteUser(user: User) {
    return this.httpDo("delete_user", user)
  }

  createUser(user: User) {
    return this.httpDo("create_user", user)
  }

  private httpDo(name: string, user: User = null) {
    return this.http.post(`${this.baseUrl}/${name}.php`, {data: user, pass: this.password}).pipe(
      map((res) => {
        this.users = res['data']
        return this.users
      }),
      catchError(this.handleError)
    )
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error)
    if (error.status == 406) {
      this.users = error.error["data"]
      return throwError(error.error["data"])
    } else if (error.status == 400) {
      return throwError('Invalid user details!')
    } else {
      return throwError(error.error.text);
    }
  }
}
