<h3 style="margin-bottom:16px;">Create user:</h3>

<button pButton pRipple type="button" [icon]="creating ? 'pi pi-times' : 'pi pi-user-plus'" 
class="p-button-rounded p-button-info p-button-raised" 
[ngClass]="creating ? 'p-button-outlined p-button-rounded p-button-info p-button-raised' : 'p-button-rounded p-button-info p-button-raised'"
[label]="creating ? 'Cancel' : 'New User'" style="float:left;margin-right:16px;margin-bottom:16px;margin-top:1px;" 
(click)="newUserClick()"></button>

<button *ngIf="creating && (newUser.username!='' || newUser.password!='')" pButton pRipple type="button" icon="pi pi-times" 
class="p-button-outlined p-button-rounded p-button-warning p-button-raised" 
label="Clear" style="float:left;margin-right:16px;margin-bottom:16px;margin-top:1px;" 
(click)="clear()"></button>

<br>
<br>
<br>
<br>

<div *ngIf="creating" class="p-float-label" style="float:left;margin-right:16px;margin-bottom:16px;">
    <input id="username-input" #usernameinput type="text" class="form-control" pInputText 
    [disabled]="!creating" [(ngModel)]="newUser.username">
    <label for="username-input">Username</label>
</div>

<div *ngIf="creating" class="p-float-label" 
    style="float:left;margin-right:12px;margin-bottom:16px;">
    <input id="password-input" #passwordinput type="text" class="form-control" pInputText 
    [disabled]="!creating" [(ngModel)]="newUser.password">
    <label for="password-input">Password</label>
</div>

<button *ngIf="creating" pButton pRipple type="button" icon="pi pi-user-plus" 
class="p-button-rounded p-button-success p-button-raised" #createBtn
label="Create" style="float:left;margin-right:16px;margin-bottom:16px;margin-top:1px;" 
(click)="createUser()" [disabled]="newUser.username=='' || newUser.password==''"></button>

<p-message *ngIf="createError!=''" severity="error" [text]="createError"></p-message>