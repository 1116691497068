import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { User } from '../users.component';
import {Message, ConfirmationService} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-user-new',
  templateUrl: './user-new.component.html',
  styleUrls: ['./user-new.component.css'],
  providers: [ConfirmationService]
})
export class UserNewComponent implements OnInit {
  @ViewChild('usernameinput') usernameInput: ElementRef
  @ViewChild('passwordinput') passwordInput: ElementRef
  @ViewChild('createBtn') createBtn: ElementRef

  creating: Boolean = false;

  newUser: User = {id: -1, username: "", password: "", used: null};
  @Output() createUserEvent = new EventEmitter<User>();

  @Input() createError: string

  constructor(private primengConfig: PrimeNGConfig) { }

  ngOnInit(): void {this.primengConfig.ripple = true;}

  newUserClick() {
    this.creating=!this.creating
    this.createError = ''
    setTimeout(() => {
      if (this.creating) {
        this.usernameInput.nativeElement.focus()
      }
    })
  }

  clear() {
    this.newUser = {id: -1, username: "", password: "", used: null}
    setTimeout(() => {
      this.usernameInput.nativeElement.focus()
    })
  }

  createUser() {
    this.createUserEvent.emit({id: -1, username: this.newUser.username, password: this.newUser.password, used: null})
    setTimeout(() => {
      if (this.createError.includes("username")) {
        this.usernameInput.nativeElement.focus()
      } else if (this.createError.includes("password")) {
        this.passwordInput.nativeElement.focus()
      } else if (this.createError == '') {
        this.clear()
      }
    })
   }

}
