<p-toast position="bottom-left" key="t" [baseZIndex]="5000"></p-toast>

<div>
    <app-user-select
        [users]="users"
        (selectedUserEvent)="setSelectedUser($event)"
        [loading]="loading"></app-user-select>

    <br>
    <br>
    <br>
    
    <app-user-show *ngIf="selectedUser.id != -1"
        [selectedUser]="selectedUser"
        (editUserEvent)="editUser($event)"
        (deleteUserEvent)="deleteUser($event)"></app-user-show>

    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" closable="true" closeOnEscape="true"></p-confirmDialog>

    <app-user-new
        (createUserEvent)="createUser($event)"
        [createError]="createError"></app-user-new>

</div>
