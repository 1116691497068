<h3 style="margin-bottom:40px;">Selected user:</h3>

<button pButton pRipple type="button" [icon]="editable? 'pi pi-check' : 'pi pi-user-edit'" class="p-button-rounded p-button-info" 
[label]="editable ? '' : 'Edit'" [disabled]="selectedUser.id == -1 || selectedUser.username == '' || selectedUser.password == ''"
[ngClass]="editable ? 'p-button-rounded p-button-success' : 'p-button-rounded p-button-info p-button-outlined'" 
(click)="editClick()" style="float:left;margin-right:16px;margin-bottom:16px;margin-top:1px;"></button>

<button *ngIf="editable" pButton pRipple type="button" icon="pi pi-times"
class="p-button-rounded p-button-danger p-button-outlined" (click)="cancel()"
style="float:left;margin-right:16px;margin-bottom:16px;margin-top:1px;"></button>

<div class="p-float-label" style="float:left;margin-right:16px;margin-bottom:16px;">
    <input id="username-input" type="text" #usernameinput class="form-control" pInputText [disabled]="!editable"
    [(ngModel)]="selectedUser.username">
    <label for="username-input">Username</label>
</div>

<div class="p-float-label" style="float:left;margin-right:12px;margin-bottom:16px;">
    <input id="password-input" [type]="showPassword ? 'text' : 'password'" class="form-control" pInputText [disabled]="!editable"
    [(ngModel)]="selectedUser.password">
    <label for="password-input">Password</label>
</div>

<button pButton pRipple type="button" [icon]="showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'" 
[disabled]="selectedUser.id == -1" class="p-button-rounded p-button-text"
(click)="showPassword = !showPassword" style="float:left;margin-right:24px;margin-bottom:16px;margin-top:1px;"></button>

<div class="p-field-checkbox" style="float:left;margin-top:8px;margin-right:48px;margin-bottom:16px;">
    <p-checkbox name="used" binary="true" inputId="used" [disabled]="!editable"
    [(ngModel)]="selectedUser.used" ></p-checkbox>
    <label for="used" style="margin-left:8px;">Used</label>
</div>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" closable="true" closeOnEscape="true"></p-confirmDialog>

<button pButton pRipple type="button" label="Delete" [disabled]="editable" class="p-button-rounded p-button-raised p-button-danger" 
icon="pi pi-trash" styleClass="p-button-lg" style="float:left" (click)="deleteUser()"></button>