import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../users.component'

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.css']
})
export class UserSelectComponent implements OnInit {
  @Input() users: User[];

  selectedUser: User;
  @Output() selectedUserEvent = new EventEmitter<User>();

  @Input() loading: boolean = false;

  constructor() { }

  ngOnInit(): void {}
  
  setSelectedUser() {
    this.selectedUserEvent.emit(this.selectedUser)
  }
}
