import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {
  @ViewChild('passwordinput') passwordInput: ElementRef

  password: string = '';
  showPassword: boolean = false;
  incorrect = false;

  constructor(public authService: AuthService, private messageService: MessageService, public router: Router) {
    if (this.authService.isLoggedInVal) {
      this.router.navigate([''])
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.passwordInput.nativeElement.focus()
    })
    if (this.authService.isLoggedInVal) {
      this.router.navigate([''])
    }
  }

  private doLogin() {
    console.log('loggedInVal'+this.authService.isLoggedInVal)
    this.authService.authLogin(this.password).pipe(first()).subscribe(
      (res: boolean) => {
        console.log(this.authService.isLoggedInVal)
        if (this.authService.isLoggedInVal) {
          this.router.navigate([''])
        } else if (this.password != '') {
          this.incorrect = true
        }
      },
      errorRes => {
        console.log('login component error')        
        if (this.password != '') {
          this.incorrect = true
        }
      }) 
  }

}
