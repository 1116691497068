<div style="width:100%;text-align: center;">
<div [style]="mobile ? 'width:100%; margin: 0;' : 'width:60%;margin:auto;margin-top:2em;'" style="background:#e3f2ff;text-align:left;">
<div style="width:100%;background:#97caf3;padding:24px;">
<img style="width:84px;vertical-align: bottom;" src="/assets/webtalent_logo.png"><span style="font-weight:bold;color:white;"> Webtalent SMS Users Admin</span>
</div>
<br><br>
<div style="width:100%;padding:24px;">

<router-outlet></router-outlet>

<br>
<br>
<br>
<br>

<h4 *ngIf="authService.isLoggedInVal" style="float:left;margin-right:16px;margin-bottom:16px;margin-top:8px;" >Logged in as Admin</h4>

<button *ngIf="authService.isLoggedInVal" pButton pRipple type="button" icon="pi pi-user" 
class="p-button-rounded p-button-secondary p-button-raised"
label="Logout"
(click)="logout()"></button>

<br>
<br>
</div>
</div>
</div>