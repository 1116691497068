import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfirmationService, MessageService, Message} from 'primeng/api';
import {DatabaseService} from '../database.service'
import { Observable } from 'rxjs';

export interface User {
  id: number,
  username: string,
  password: string,
  used: number
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class UsersComponent implements OnInit {

  users: User[];
  selectedUser: User = {id: -1, username: "", password: "", used: null};


  createError = ''
  loading: boolean = false

  constructor(private confirmationService: ConfirmationService, private messageService: MessageService, private database: DatabaseService) { 
    this.users = []
  }

  ngOnInit(): void {
    this.getUsers()
  }

  setSelectedUser($event) {
    this.selectedUser = $event
  }

  getUsers() {
    this.httpGet(this.database.getAll, 'Users loaded', 'Error retrieving users')
  }

  editUser($event: User) {
    this.httpPost(this.database.editUser.bind(this.database), $event, "edited", "editing", "User doesn't exist")
  }

  deleteUser($event: User) {
    this.httpPost(this.database.deleteUser.bind(this.database), $event, "deleted", "deleting", "User doesn't exist or was changed", 
    () => {
      this.selectedUser = {id: -1, username: "", password: "", used: null}
    })
  }

  createUser($event: User) {
    if (this.users.map((user) => user.username).includes($event.username)) {
      this.createError = "This username exists already!"
    } 
    else if (this.users.map((user) => user.password).includes($event.password)) {
      this.createError = "This password exists already!"
    } else {
      this.createError = ''
    }
    if (this.createError == '') {
      this.httpPost(this.database.createUser.bind(this.database), $event, "created", "creating", "Username or password exists already")
    }
  }

  private httpGet(dbAction: () => Observable<User[]>, success: string, error: string, onComplete: () => any = null) {
    this.httpDo(dbAction.bind(this.database), onComplete, success, error)
  }

  private httpPost(dbAction: (User) => Observable<User[]>, user: User, actioned: string, actioning: string, 
  error406: string, onComplete: () => any = null) {
    this.httpDo(() => dbAction(user), onComplete,
    '\'User\' '+actioned, 'Error '+actioning+' user', error406+". Please try again or refresh the page")
  }

  private httpDo(dbAction: () => Observable<User[]>, onComplete: () => any, success: string, error: string, error406: string="") {
    console.log('users component httpDo')    
    this.loading = true
    dbAction().subscribe(
      (res: User[]) => {
        this.users = this.database.users;
        this.users = this.users.map(user => {return user});
        if (onComplete != null) {
          onComplete()
        }
        this.messageService.add({key: 't', severity:'success', detail: success});
        this.loading = false
      },
      errorRes => {
        if (typeof errorRes == "string") {
          this.messageService.add({key: 't', severity:'error', detail: error+' ('+errorRes+')'});
        } else if (typeof errorRes != "undefined") {
          this.messageService.add({key: 't', severity:'error', detail: error406});
          this.users = this.database.users;
          this.users = this.users.map(user => {return user});
        }
        this.loading = false
      }
    );
  }
}
