import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'WebtalentSMS Admin';
  mobile: boolean;
  constructor(public authService: AuthService, public router: Router) {}

  ngOnInit() {
    if (window.screen.width <= 800) { // 768px portrait
      this.mobile = true;
    } 
  }

  logout() {
    this.authService.logout()
    this.router.navigate(['/login'])
  }
}
